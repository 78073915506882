import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

const PrivacyPage = () => (
  <div>
    <Header/>

    <div className="content-pad white-bg grey-bg">
      <h3>Privacy Policy</h3>
      <p>
        <i>Ultimo aggiornamento: 27/1/2023</i>
      </p>
      <p>
        La presente Privacy Policy è finalizzata ad illustrare come trattiamo i dati personali che ci comunicherete
        entrando in contatto con i nostri uffici attraverso qualsiasi canale (telematico, telefonico, postale, etc.).
      </p>
      <p>
        Fondazione Silene Ideas For Care - Ente del Terzo Settore, in qualità di Titolare del trattamento, garantisce
        e si assicura costantemente che i vostri dati personali siano sempre trattati nel rispetto delle prescrizioni
        previste dal Regolamento UE 2016/679 ("GDPR") e del D.lgs. 196/2003 (Codice Privacy italiano come modificato
        dal D.lgs. 101/2018) in materia di protezione dei dati.
      </p>
      <p>
        La presente informativa privacy ha, pertanto, lo scopo di illustrarvi le modalità con cui la nostra Società
        raccoglie, utilizza, divulga, memorizza ed eventualmente trasferisce i vostri dati personali, ai sensi e per
        gli effetti di cui agli artt. 13 GDPR.
      </p>

      <h4>A. Identità e dati di contatto del Titolare del trattamento</h4>
      <p>
        Il Titolare del trattamento è Fondazione Silene Ideas For Care - Ente del Terzo Settore con sede legale in Roma,
        Viale Gorizia 52, 00198, Codice Fiscale e P.IVA 16222231009 (di seguito "Società" o "Titolare" o "Fondazione
        Silene Ideas For Care"). Per qualsiasi richiesta in merito al trattamento dei vostri dati personali,
        vi preghiamo di scrivere a <a href="mailto:info@silenefoundation.org">info@silenefoundation.org</a>
      </p>

      <h4>B. Finalità del trattamento, base giuridica e legittimo interesse</h4>
      <p>
        Il trattamento dei vostri dati personali da parte di Fondazione Silene Ideas For Care può avvenire in base a:
      </p>
      <ul className="bigger-text">
        <li>
          il vostro consenso (ad esempio, compilando il form di contatto del sito web oppure contattando i nostri
          uffici per informazioni, consulenze, ordini, invio di cv, etc.);
        </li>
        <li>
          un contratto (qualora siate, ad esempio, clienti, fornitori o consulenti della nostra Società);
        </li>
        <li>
          un obbligo legale (quando alla nostra Società sono richiesti specifici adempimenti derivanti da norme di legge);
        </li>
        <li>
          un legittimo interesse della Società (in caso di adempimenti strettamente connessi con il rapporto da voi
          instaurato con la nostra Società), sempre tenuto conto del necessario bilanciamento tra il nostro interesse e i
          vostri diritti e libertà.
        </li>
      </ul>
      
      <h4>C. Protezione dei vostri dati ed eventuali destinatari degli stessi</h4>
      <p>
        I vostri dati personali sono trattati esclusivamente da nostro personale, autorizzato e formato al trattamento di
        tali dati e, in ogni caso, sotto la responsabilità del Titolare del trattamento ed esclusivamente per le finalità
        in relazione alle quali i vostri dati sono stati raccolti. Fondazione Silene Ideas For Care adotta misure tecniche
        ed organizzative idonee ed adeguate per garantire la sicurezza dei vostri dati personali, proteggendoli da perdita,
        furto e/o abusi, nonché da accessi non autorizzati, rivelazione, alterazione e/o distruzione, sia volontaria sia
        accidentale. I vostri dati personali sono conservati esclusivamente presso la nostra Società e non vengono
        comunicati o trasmessi a terzi senza il vostro preventivo consenso. Fondazione Silene Ideas For Care potrebbe,
        tuttavia, essere obbligata, in alcuni casi, a trasmettere i vostri dati personali, in tutto o in parte, a terzi
        in dipendenza di obblighi di legge, procedimenti giudiziari di qualsivoglia genere o specie e/o richieste da parte
        di autorità pubbliche.
      </p>

      <h4>D. Newsletter</h4>
      <p>
        I dati personali che ci comunicate iscrivendovi alla nostra newsletter sono il vostro indirizzo email.
      </p>
      <p>
        Trattiamo tali dati in base al vostro consenso espresso e li conserveremo fino a quando non deciderete liberamente
        di revocarlo. Per l'invio della nostra newsletter i vostri dati di contatto sono trattati anche da mailchimp,
        gestore di marketing automation che ricopre il ruolo di Responsabile del trattamento ai sensi dell'art. 28 GDPR.
        Abbiamo scelto mailchimp perché, previo rilascio del consenso dell'interessato, garantisce misure di sicurezza,
        tecniche ed organizzative, adeguate alla tutela dei dati personali dei nostri utenti e impone ai propri clienti
        e utenti stringenti regole contro gli abusi.
      </p>

      <h4>E. Trasferimento dei dati in Paesi terzi</h4>
      <p>
        Fondazione Silene Ideas For Care non trasferisce i vostri dati presso Paesi terzi né ad Organizzazioni
        Internazionali, senza adeguate protezioni di natura tecnica, organizzativa, normativa e contrattuale, in
        conformità alla legislazione vigente.
      </p>

      <h4>G. Diritti dell'interessato</h4>
      <p>
        Potete scrivere all'indirizzo di contatto indicato alla precedente "Sezione A" della presente informativa per
        esercitare i seguenti diritti:
      </p>
      <ul className="bigger-text">
        <li>
          diritto di accesso ai dati personali; diritto di ottenere la rettifica o la cancellazione degli stessi o la
          limitazione del trattamento che li riguardano;
        </li>
        <li>
          diritto di opporsi al trattamento;
        </li>
        <li>
          diritto alla portabilità dei dati, in relazione ai soli dati in formato elettronico, nei limiti stabiliti
          dall'art. 20 del GDPR.
        </li>
      </ul>
      <p>
        In relazione ai dati per i quali la base giuridica del trattamento è il vostro consenso, avete diritto di
        revocare tale consenso in qualsiasi momento, senza pregiudicare la liceità del trattamento effettuato dal
        Titolare e basata sul consenso da voi fornito prima della revoca.
      </p>

      <h4>H. Reclamo</h4>
      <p>
        Vi informiamo, inoltre, che avete diritto, ricorrendone le condizioni, di proporre reclamo all'Autorità di
        controllo territorialmente competente, così come individuata dagli artt. 3, 55 e 56 GDPR. Per approfondimenti
        e maggiori informazioni, vi invitiamo a visitare il sito istituzionale del Garante della protezione dei dati
        personali al seguente indirizzo web: <a href="www.garanteprivacy.it" target="_blank">www.garanteprivacy.it</a>
      </p>

      <h4>I. Comunicazioni e conferimento dei dati</h4>
      <p>
        Fermo quanto sopra, la comunicazione dei vostri dati personali potrebbe costituire un obbligo legale e/o un
        obbligo contrattuale e, in ogni caso, un requisito necessario per la conclusione ed esecuzione degli impegni
        assunti dalla nostra Società. Ciò significa che, in questi casi, il mancato conferimento dei dati da parte
        vostra potrebbe comportare l'impossibilità per la nostra Società di dar seguito alle vostre richieste.
      </p>
    </div>

    <Footer/>
  </div>
)

export default PrivacyPage
